import React from "react";

import heroImage from "../../assets/img/image 84.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section3 from "../../assets/img/image 58.webp";
import section1 from "../../assets/png/shaps/possect1.png";

import BasicSection from "../../components/BasicSection/BasicSection";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
import ThreeCardsSection from "../../components/ThreeCardsSection/ThreeCardsSection";
import screen from "../../assets/img/posDashboard.png";
import CircleSection from "../../components/CircleSection/CircleSection";
import { Col, Container, Row } from "react-bootstrap";
export function POSSystemPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"Point of Sale (POS) System"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="Point of Sale (POS) System"
      />

      <BasicSection
        title={t("posSection1Title1")}
        body={t("posbody")}
        image={section1}
        reverse={false}
      />
        <ThreeCardsSection />


        <section id="" className="my-5">
        <Container className="p-0">
          <Row className="justify-content-center">
            <Col sm={9} xs={12}>
              <img
                src={screen}
                className="img-fluid d-block m-auto"
                alt="cloud"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <CircleSection
        title={t("Why Choose us")}
        body={t("whyUSBody")}
        image={section3}
        reverse={false}
      />
    </>
  );
}
export default POSSystemPage;
