
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./FooterComponent.scss";
import * as React from "react";
import logo from "../../assets/logo.png";
import facebookIcon from "../../assets/png/icons/facebook.png";
import instagramIcon from "../../assets/png/icons/instagram.png";
import twitterIcon from "../../assets/png/icons/twitter.png";
import linkedinIcon from "../../assets/png/icons/linkedin.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLang } from "../../context/LangState";
import logoAr from "../../assets/logoAr.png";
import { ToastContainer, Toast } from "react-bootstrap";
import axios from "axios";
const baseURL = "http://cs-api.cs-testing.com/api/v1/en/contact_us";
export function FooterComponent() {
  const { t } = useTranslation();
  const { toggleLang, lang } = useLang();
  const currentYear = new Date();

  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState(String);
  const [toastValue, setToast] = React.useState(Boolean);
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const sendContact = () => {
    setIsLoading(true);
    setToast(true);
    console.log("toast", toastValue);

    axios
      .post(baseURL, { email: email, phone: phone })
      .then((response: any) => {
        setIsLoading(false);
        if (response.data.check) {
          setMessage(response.data.msg);
          setToast(true);
          resetContact();
        } else {
          setToast(true);
          setMessage(response.data.msg);
        }
      });
  };
  const resetContact = () => {
    setPhone("");
    setEmail("");
    setIsValid(false);
    setIsLoading(false);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };
  React.useEffect(() => {
    return () => {
      if (email && phone) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };
  });

  return (
    <>
      <footer>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={2} md={12} className="my-3">
              {lang == "ar" ? (
                <img src={logoAr} className="logo img-fluid" alt="" />
              ) : (
                <img src={logo} className="logo img-fluid" alt="" />
              )}

              <div id="logo_text">
               <small>{t("cloud secrets")}</small> <br />
               <small>{t("C.R")}: 1010625386</small> 
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <img src={facebookIcon} className="icons img-fluid" alt="" />
                <img src={instagramIcon} className="icons img-fluid" alt="" />
                <img src={twitterIcon} className="icons img-fluid" alt="" />
                <img src={linkedinIcon} className="icons img-fluid" alt="" />
              </div>
            </Col>
            <Col lg={'auto'} xs={'auto'} className="my-3">
              <NavLink to="/our-projects">{t("Applications")}</NavLink>
            </Col>
            <Col lg={'auto'} xs={'auto'} className="my-3">
              <NavLink to="/about-us">{t("About us")}</NavLink>
            </Col>
            <Col lg={'auto'} xs={'auto'} className="my-3">
              <NavLink to="/services">{t("Services")}</NavLink>
            </Col>
            <Col lg={'auto'} xs={'auto'} className="my-3">
              <NavLink to="/privacy-policy">{t("Privacy Policy")}</NavLink>
            </Col>
            <Col lg={'auto'} xs={'auto'} className="my-3">
              <NavLink to="/careers">{t("Careers")}</NavLink>
            </Col>
            <Col lg={'auto'} md={12} className="my-3">
              <h5 className="text-primary text-center p-3">
                {t("Contact us")}
              </h5>
              <Form.Control
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder={t("email")}
                className={"my-3"}
              />
              <Form.Control
                type="number"
                value={phone}
                onChange={handlePhoneChange}
                placeholder={t("Phone Number")}
                className={"my-3"}
              />
              <Button
                variant="outline-primary"
                className="d-block m-auto text-primary border-0 w-100  shadow-sm"
                onClick={sendContact}
                disabled={!isValid || isLoading}
              >
                {isLoading ? t("Sending…") : message ? message : t("Send")}
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={"auto"}>
              <p className="text-primary">
                <small>
                  {t("All rights reserved to")}{" "}
                  <strong>{t("cloud secrets")}</strong> ©{" "}
                  {currentYear.getFullYear()}{" "}
                </small>
              </p>
            </Col>
          </Row>

        </Container>
        <ToastContainer className="p-3" position="bottom-center">
          <Toast
            onClose={() => { setToast(false); setMessage('') }}
            delay={4000}
            show={toastValue}
            autohide
          >
            <Toast.Body>
              <strong> {message}</strong>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </footer>
    </>
  );
}
export default FooterComponent;
