import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./MissionVisionSection.scss";
import saudiIllustrat from "../../assets/png/shaps/saudiIllustrat.png";
import { useTranslation } from "react-i18next";
export function MissionVisionSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="missionVisionSection">
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col lg={6} md={12}>
              <Card>
                <h4 className={"cloudTitle"}>{t("Vision")} </h4>
                <p>
                  {t("visionBody1")}
                  <br /> {t("visionBody2")}
                </p>
              </Card>
            </Col>
            <Col lg={6} md={12}>
              <Card>
                <h4 className={"cloudTitle"}>{t("Goal")} </h4>
                <p>{t("goalBody")}</p>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default MissionVisionSection;
