import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import bgVideo from "../../assets/videos/cloud.webm";
import bgVideoMob from "../../assets/videos/cloudMob.webm";
import "./LandingSection.scss";

export function LandingSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="landingSection">
        <Container fluid>
          <video
            id="videoLG"
            src={bgVideo}
            loop={true}
            autoPlay={true}
            muted={true}
          />
          <video
            id="videoMD"
            src={bgVideo}
            loop={true}
            autoPlay={true}
            muted={true}
          />
          <video
            id="videoSM"
            src={bgVideo}
            loop={true}
            autoPlay={true}
            muted={true}
          />

          <Col xs={12} style={{marginTop: '32vh'}}>
            <Row className="justify-content-center">
              <Col lg={7} xs={11}>
                <h1 id="intro_title" className="text-center my-3">{t("introTitle")}</h1>
                <h6 className="text-center my-5">{t("welcomeBody")}</h6>
              </Col>
            </Row>
          </Col>
        </Container>
      </section>
    </>
  );
}
export default LandingSection;
