import React from "react";

import heroImage from "../assets/img/image 94.png";
import BasicSection from "../components/BasicSection/BasicSection";
import CircleSection from "../components/CircleSection/CircleSection";
import elipse from "../assets/img/box-elips.png";
import HeroSection from "../components/HeroSection/HeroSection";
import ProjectsSection from "../components/ProjectsSection/ProjectsSection";
import img2 from "../assets/img/image 89.png";
import defaultImg from "../assets/img/default.jpg";
import darkRectangels from "../assets/img/box-rectangle.png";
import darkRectangelsAr from "../assets/img/box-rectangle-ar.png";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";

import { useLang } from "../context/LangState";
import { useTranslation } from "react-i18next";
import ThreeCardsSection from "../components/ThreeCardsSection/ThreeCardsSection";
export function KeratPage() {
  const { lang } = useLang();
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - " + t("Qarat");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection
        title={"Qarat"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Applications"
        parentLink="/our-projects"
        activePage="Qarat"
      />

      <BasicSection
        title={t("qaratSection1Title1")}
        body={t("qeratBody")}
        backgroundImg={lang == "ar" ? darkRectangelsAr : darkRectangels}
        textColor={"#fff"}
        reverse={false}
      />
      
      <ThreeCardsSection />

      <CircleSection
        title={t("qaratSection2Title1")}
        body={t("qaratSection2Body1") + " " + t("qaratSection2Body2") + " " + t("qaratSection2Body3") +" " + t("qaratSection2Body4")}
        image={img2}
        reverse={false}
      />

      <HeroSection
        title={"Do not think too much"}
        body={""}
        linkText={"subscribe now"}
        linkOut={"https://www.qaraterp.com/ar"}
        heightAuto={"auto"}
        backgroundImg={elipse}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />
    </>
  );
}
export default KeratPage;
