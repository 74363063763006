import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import ReactDOM from "react-dom";
import "./index.css";
import "./i18next";
import App from "./App";
import React, { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LangProvider } from "./context/LangState";
import LoaderComponent from "./components/Loader/Loader";
import { Container, Spinner } from "react-bootstrap";
ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <Container
          fluid
          className="d-flex justify-content-between align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <Spinner animation="grow" variant="primary" className="m-auto" />
        </Container>
      }
    >
      <BrowserRouter>
        <LangProvider><App /></LangProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
