import React from "react";

import heroImage from "../../assets/img/ceco g.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section2 from "../../assets/png/shaps/website.png";
import elipse from "../../assets/img/box-elips.png";
import BasicSection from "../../components/BasicSection/BasicSection";
import defaultImg from "../../assets/img/default.jpg";
import comp from "../../assets/png/shaps/eco.png";
import dachord from "../../assets/svg/vectors/dachord.svg";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
import ThreeCardsSection from "../../components/ThreeCardsSection/ThreeCardsSection";
export function ECOSystemPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"E-Commerce System"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="E-Commerce System"
      />

      <BasicSection
        title={t("ecoSection1Title1")}
        body={t("eCommerceSystems")}
        image={comp}
        reverse={false}
      />
      <ThreeCardsSection />
      <BasicSection
        title={t("ecoSection1Title2")}
        body={t("ecoSection1Body2")}
        image={section2}
        reverse={false}
      />
      <HeroSection
        title={"Leave the past and join..... with us towards the future"}
        body={""}
        heightAuto={"150px"}
        backgroundImg={elipse}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />
    </>
  );
}
export default ECOSystemPage;
