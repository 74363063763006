import React from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import heroImage from "../assets/img/image 90.png";
import { useTranslation } from "react-i18next";
export function ErrorPage404() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection
        title={"Page Not Found..404"}
        body={
          "The Page You are looking for doesn't exist or an another error occured"
        }
        backgroundImg={heroImage}
        mouseScroll={false}
        linkText="Home Page"
        link="/"
      />
    </>
  );
}
export default ErrorPage404;
