import React from "react";

import heroImage from "../../assets/img/image 86.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section1 from "../../assets/png/shaps/Group 170.png";
import section2 from "../../assets/png/shaps/website.png";
import elipse from "../../assets/img/box-elips.png";
import BasicSection from "../../components/BasicSection/BasicSection";
import defaultImg from "../../assets/img/default.jpg";
import dachord from "../../assets/img/devices.png";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
export function ErpSystemPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"(ERP System)Enterprise Resource Planning"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="(ERP System)Enterprise Resource Planning"
      />
      <BasicSection
        title={
          t("what is") + " " + t("(ERP System)Enterprise Resource Planning")
        }
        body={t("erpSystem")}
        image={section1}
        reverse={false}
      />
      <BasicSection
        title={
          t("what is") + " " + t("(ERP System)Enterprise Resource Planning")
        }
        body={t("erpsec1")}
        image={section2}
        backgroundColor="#313d5e"
        textColor="#fff"
        reverse={false}
      />

      <section id="" className="my-5">
        <Container className="p-0">
          <Row className="justify-content-center">
            <Col sm={9} xs={12}>
              <img
                src={dachord}
                className="img-fluid d-block m-auto"
                alt="cloud"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <HeroSection
        title={"Leave the past and join..... with us towards the future"}
        body={""}
        heightAuto={"150px"}
        backgroundImg={elipse}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />
    </>
  );
}
export default ErpSystemPage;
