import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./NavbarComponent.scss";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import logo from "../../assets/logo.png";
import logoAr from "../../assets/logoAr.png";
import logoW from "../../assets/logo en.svg";
import logoArW from "../../assets/logo are.svg";
import darkMenu from "../../assets/png/icons/dark-menu.png";
import whiteMenu from "../../assets/png/icons/white-menu.png";
import langHover from "../../assets/png/icons/global2.png";
import langDark from "../../assets/png/icons/global.png";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useLang } from "../../context/LangState";
import MenuSidebar from "./MenuSidebar";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function NavbarComponent() {
  const [change, setChange] = React.useState(false);
  const changePosition = 100;

  let position = useWindowScrollPosition();

  if (position.y > changePosition && !change) {
    setChange(true);
  }

  if (position.y <= changePosition && change) {
    setChange(false);
  }

  const { t } = useTranslation();
  const { toggleLang, lang } = useLang();
  console.log("lang", lang);
  console.log("sasa", window);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <nav id="navbar" className={change ? "light-nav shadow-sm" : ""}>
        <Container className="p-0">
          <Row className="align-items-center justify-content-around">
            <Col xs={"auto"} className="ShowInSmallNav">
              {change ? (
                <img
                  src={darkMenu}
                  className="img-fluid d-block m-auto"
                  style={
                    lang == "en"
                      ? {
                        transform: "rotate(180deg)",
                      }
                      : { transform: "rotate(0deg)" }
                  }
                  alt={""}
                  onClick={() => setMenuOpen(!menuOpen)}
                />
              ) : (
                <img
                  src={whiteMenu}
                  className="img-fluid d-block m-auto"
                  style={
                    lang == "en"
                      ? {
                        transform: `rotate(180deg)') `,
                      }
                      : { transform: `rotate(0deg)') ` }
                  }
                  alt={""}
                  onClick={() => setMenuOpen(!menuOpen)}
                />
              )}
            </Col>
            <Col md="auto" className="hideInSmallNav">
              <NavLink to="/" exact activeClassName={"activeLink"}>
                {t("Home")}
              </NavLink>
            </Col>
            <Col md="auto" className="hideInSmallNav">
              <p>{t("Services")}</p>
              <div className="menuList">
                <ul>
                  <li>
                    <NavLink
                      to="/services"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Services Summary")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/design-programming"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("website design and programming")}.
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/services/erp-system"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("(ERP System)Enterprise Resource Planning")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/e-commerce"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("E-Commerce System")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/mobile-applications"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Design and Programming of Mobile Applications")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/hr-system"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("HR System")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/pos-system"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Point of Sale (POS) System")}.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/special-applications"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Design and programming of special applications")}.
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="auto" className="hideInSmallNav">
              <p>{t("Applications")}</p>
              <div className="menuList">
                <ul>
                  <li>
                    <NavLink
                      to="/kader"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Kader")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/kerat"
                      exact
                      activeClassName={"activeLinkMenu"}
                    >
                      {t("Qarat")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="auto" xs={"auto"} className="navLogo">
              <NavLink to="/">
                {lang == "ar" ? (
                  <img
                    src={logoAr}
                    className="img-fluid darkLogo"
                    alt="cloud secrets"
                  />
                ) : (
                  <img
                    src={logo}
                    className="img-fluid darkLogo"
                    alt="cloud secrets"
                  />
                )}
                {lang == "ar" ? (
                  <img
                    src={logoArW}
                    className="img-fluid whiteLogo"
                    alt="cloud secrets"
                  />
                ) : (
                  <img
                    src={logoW}
                    className="img-fluid whiteLogo"
                    alt="cloud secrets"
                  />
                )}
              </NavLink>
            </Col>
            <Col md="auto" className="hideInSmallNav">
              <NavLink to="/about-us" exact activeClassName={"activeLink"}>
                {t("About us")}
              </NavLink>
            </Col>
            <Col md="auto" className="hideInSmallNav">
              <NavLink to="/blogs" exact activeClassName={"activeLink"}>
                {t("Blog")}
              </NavLink>
            </Col>
            <Col md="auto" className="hideInSmallNav langButton">
              <a className="d-flex" onClick={toggleLang}>
                {t("English")}
                <img src={langDark} alt={""} />
                <img src={langHover} alt={""} />
              </a>
            </Col>
            <Col md="auto" className={"contact-us hideInSmallNav"}>
              <NavLink to="/contact-us" exact activeClassName={"activeLink"}>
                {t("Contact us")}
              </NavLink>
            </Col>
          </Row>
        </Container>
      </nav>
      {/* Menu */}

      {menuOpen && (
        <div id="menuSideBar">
          <MenuSidebar closeMenu={closeMenu} />
        </div>
      )}
    </>
  );
}
export default NavbarComponent;
