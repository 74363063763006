import React from "react";

import heroImage from "../../assets/img/image 82.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section3 from "../../assets/img/image 58.webp";
import section1 from "../../assets/png/shaps/mobb.png";
import elipse from "../../assets/img/box-elips.png";
import BasicSection from "../../components/BasicSection/BasicSection";
import StepsImg1 from "../../assets/png/shaps/Group 122.png";
import StepsImg2 from "../../assets/png/shaps/Group 121.png";
import StepsImg3 from "../../assets/png/shaps/Group 120.png";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
import ThreeCardsSection from "../../components/ThreeCardsSection/ThreeCardsSection";
import StepsSection from "../../components/StepsSection/StepsSection";
import CircleSection from "../../components/CircleSection/CircleSection";
export function MobileAppPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"Design and Programming of Mobile Applications"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="Design and Programming of Mobile Applications"
      />

      <BasicSection
        title={t("mobSection1Title1")}
        body={t("mobileApplications")}
        image={section1}
        reverse={false}
      />
      <StepsSection
        title={t("websiteServiceSection2Title")}
        steps={[
          {
            stepTitle: t("Design the user interface and user experience"),
            image: StepsImg1,
      
          },
          {
            stepTitle: t("Application development and creation on high-resolution servers"),
            image: StepsImg2,
           },
          {
            stepTitle: t("Improve your old apps and make them more friendly"),
            image: StepsImg3,
      
          },
        ]}
        backgroundColor="#313D5E"
      />
      <CircleSection
        title={t("Why Choose us")}
        body={t("whyUSBody")}
        image={section3}
        reverse={false}
      />
    </>
  );
}
export default MobileAppPage;
