import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./ThreeCardsSection.scss";
import cloudSmall from "../../assets/png/shaps/cloud small.png";
import cloud from "../../assets/png/shaps/cloud big.png";
import { useTranslation } from "react-i18next";
import reportIcon from "../../assets/png/icons/report.png";
import reportIconActive from "../../assets/png/icons/hover/report.png";
import translationIcon from "../../assets/png/icons/translation.png";
import translationIconActive from "../../assets/png/icons/hover/translation.png";
import dashIcon from "../../assets/png/icons/dach icon.png";
import dashIconActive from "../../assets/png/icons/hover/dach icon.png";
export function ThreeCardsSection() {
    const { t } = useTranslation();
  return (
    <>
      <section className="threeCardsSection">
        <div className="bgImg">
          <div></div>
        </div>

        <h3 className={"cloudTitle"}>
          <span>{t("will save you")}</span>
          {/* <img src={cloud} alt="cloud" /> */}
        </h3>

        <Container fluid>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={3} md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={dashIcon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={dashIcon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Easy control panel")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col lg={3} md={4} sm={6} xs={11} >
                <Card >
                  <img
                    src={translationIcon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={translationIconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("multilanguage")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col lg={3} md={4} sm={6} xs={11} data-aos="fade-left">
                <Card >
                  <img
                    src={reportIcon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={reportIconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Reports")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
}
export default ThreeCardsSection;
