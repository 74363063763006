// import * as React from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Row,
//   ToastContainer,
//   Toast,
// } from "react-bootstrap";
// import { useRef } from "react";
import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row, ToastContainer, Toast } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import axios from "axios";
import "./CareersSection.scss";
import { MdOutlineFileUpload } from 'react-icons/md'



const baseURL = "https://tm-api-test.cs-testing.com/api/v1/ar/careers";
const token = 'xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EBh';
export function CareersSection() {
  const fileInputRef = useRef(null);
  const { t } = useTranslation();




  const handleChange = (event:any) => {
    const { name, value } = event.target;
    console.log("name",name);
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
    console.log(formData);
  };
  
  
  const handleCV = (event:any) => {
    const file = event.target.files[0];
    setFormData((prevFormData:any) => ({
      ...prevFormData,
      cv: file,
      cvFileName: file.name,
    }));
  };
  // const [toastValue, setToast] = React.useState(Boolean);
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    job: "",
    cv: "",
    cvFileName: "",
    email: "",
    message: "",
    toastValue: false,
    isValid: false,
    isLoading: false,
  });

  const {
    fullName,
    phoneNumber,
    job,
    cv,
    cvFileName,
    email,
    message,
    toastValue,
    isValid,
    isLoading,
  } = formData;
  
  // const [FullName, setFullName] = React.useState("");
  // const [PhoneNumber, setPhoneNumber] = React.useState("");
  // const [job, setJob] = React.useState("");
  // const [CV, setCV] = React.useState("");
  // const [CvFileName, setCvFileName] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [message, setMessage] = React.useState(String);
  // const [toastValue, setToast] = React.useState(Boolean);
  // const [isValid, setIsValid] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(false);

  const resetContact = () => {
    // setFullName("");
    // setPhoneNumber("");
    // setJob("");
    // setCV("");
    // setCvFileName("");
    // setEmail("");
    // setIsValid(false);
    // setIsLoading(false);
  };
  // const handleCV = (event: any) => {
  //   const file = event.target.files[0];
  //   setCvFileName(file.name)
  //   setCV(file);
  // };
  const handleFullName = (event: any) => {
    console.log(event.target.value);

    // setFullName(event.target.value);
  };
  // const handlePhoneNumber = (event: any) => {
  //   setPhoneNumber(event.target.value);
  // };
  // const handleEmailChange = (event: any) => {
  //   setEmail(event.target.value);
  // };
  // const handleJob = (event: any) => {
  //   setJob(event.target.value);
  // };



  
  function sendContact(): void {

    // setIsLoading(true);
    // setToast(true);

    // const formData = new FormData();
    // formData.append("full_name", FullName);
    // formData.append("phone", PhoneNumber);
    // formData.append("email", email);
    // formData.append("job", job);
    // formData.append("cv", CV);


    axios.post(baseURL, formData, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      // setIsLoading(false);
      if (response.data.check) {
        // setMessage(response.data.msg);
        // setToast(true);
        resetContact();
      }
    })
      .catch((error) => {
        console.log(error);
        // setToast(true);
        // setMessage(t("Not sent please try again"));
        // setIsLoading(false);
      });
  }

  

  // React.useEffect(() => {
  //   return () => {
  //     if (email && FullName && PhoneNumber &&  CV) {
  //       setIsValid(true);
  //     } else {
  //       setIsValid(false);
  //     }
  //   };
  // },[email , FullName , PhoneNumber ,  CV]);

  return (
    <>
      <section className="CareersSection px-0">
        <Container fluid className="p-0">
          <Container>
            <Row className={"justify-content-between align-items-center p-0"}>
              <Col xl={5} lg={6} xs={12} className="p-5">
                <h3 className="text-center text-white mt-5">
                  {" "}
                  {t("Join our team")}
                </h3>
              </Col>
              <Col xl={5} lg={6} xs={12} className="p-3" data-aos="flip-up">
                <Card className="border-0 bg-white rounded-0 p-5">

                  <Form.Group className="mb-4" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="fullName"
                      value={fullName}
                      onChange={handleChange}
                      placeholder={t("Full Name")}
                    />
                  </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicName">
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      placeholder={t("Phone number (preferably with WhatsApp)")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicName">
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder={t("email")}
                    />
                  </Form.Group>
                  
                  <Form.Select className="mb-4 form-control" name="job" value={job}  placeholder="Enter email" onChange={handleChange} >
                  <label >{t("Job")}</label>
                    <option value="">{t("Job")}</option>
                    <option value="Software Developer ( Front-end )">Software Developer ( Front-end )</option>
                    <option value="Software Developer ( Back-end )">Software Developer ( Back-end )</option>
                    <option value="Software Architect">Software Architect</option>
                    <option value="Quality Assurance Engineer">Quality Assurance Engineer</option>
                    <option value="Project Manager">Project Manager</option>
                    <option value="Business Analyst">Business Analyst</option>
                    <option value="DevOps Engineer">DevOps Engineer</option>
                    <option value="Product Designer ( UX )">Product Designer ( UX )</option>
                    <option value="Technical Writer">Technical Writer</option>
                    <option value="Sales Engineer">Sales Engineer</option>
                    <option value="Sales Representative">Sales Representative</option>
                    <option value="Account Manager">Account Manager</option>
                    <option value="Support Engineer">Support Engineer</option>
                    <option value="Data Analyst">Data Analyst</option>
                    <option value="Data Scientist">Data Scientist</option>
                    <option value="Security Engineer">Security Engineer</option>
                    <option value="Technical Recruiter">Technical Recruiter</option>
                    <option value="Human Resources Manager">Human Resources Manager</option>
                    <option value="Human Resources Representative">Human Resources Representative</option>
                    <option value="Marketing Manager">Marketing Manager</option>
                    <option value="Creative Director">Creative Director</option>
                    <option value="Copywriter">Copywriter</option>
                    <option value="Graphic Designer">Graphic Designer</option>
                    <option value="Social Media Specialist">Social Media Specialist</option>
                    <option value="SEO/SEM Specialist">SEO/SEM Specialist</option>
                    <option value="Media Buyer">Media Buyer</option>

                  </Form.Select>

                  <Form.Group className="mb-4" id='CV' controlId="formBasicName">
                    <Form.Control
                      type="file"
                      ref={fileInputRef}
                      name="cv"
                      onChange={handleCV}
                      placeholder={t("CV")}
                    />
                    <label >{!cv? t("Upload your Curriculum Vitae (CV)"):''}</label>
                    <div>{cvFileName}</div>
                    <span><MdOutlineFileUpload size={24}/></span>
                  </Form.Group>


                  <Button
                    variant="primary"
                    size="lg"
                    className="w-100 my-4 rounded-0"
                    onClick={sendContact}
                    disabled={!isValid || isLoading}
                    >
                    {isLoading ? t("Sending…") : t("Join us")}
                  </Button>
                </Card>
              </Col>
            </Row>
            {/* <ToastContainer className="p-3" position="bottom-center">
                  <Toast onClose={() => setToast(false)} delay={4000} show={toastValue} autohide>
                    <Toast.Body>
                    <strong> {message}</strong>
                    </Toast.Body>
                  </Toast>
                </ToastContainer> */}
          </Container>
        </Container>
      </section>
    </>
  );
}
export default CareersSection;
