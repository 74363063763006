import React from "react";

import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import "./BreadCrumbs.scss";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/png/icons/Rectangle 120.png";
import arrowEn from "../../assets/png/icons/Rectangle 153en.png";
import { useTranslation } from "react-i18next";
import { useLang } from "../../context/LangState";
interface BreadcrumbsPropTypes {
  parent?: string;
  parentLink?: string;
  activePage: string;
}
const BreadcrumbsComponents: React.FC<BreadcrumbsPropTypes> = ({
  parent,
  parentLink,
  activePage,
}) => {
  const { t } = useTranslation();
  const { lang } = useLang();
  return (
    <>
      <section className="breadcrumbSection shadow-sm">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={"/"}>
                {t("Home")}
                {lang == "ar"? <img src={arrow} className="mx-2" alt="" /> : <img src={arrowEn} className="mx-2" alt="" />}
              </NavLink>
            </Breadcrumb.Item>
            {parent && (
              <>
                <Breadcrumb.Item>
                  <NavLink to={`${parentLink}`}>{t(parent)}</NavLink>
                  {lang == "ar"? <img src={arrow} className="mx-2" alt="" /> : <img src={arrowEn} className="mx-2" alt="" />}
                </Breadcrumb.Item>
              </>
            )}
            <Breadcrumb.Item active> {t(activePage)} </Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
    </>
  );
};
export default BreadcrumbsComponents;
