import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./WorkStepsSection.scss";
import cloudSmall from "../../assets/png/shaps/cloud small.png";
import cloud from "../../assets/png/shaps/cloud big.png";
import { useTranslation } from "react-i18next";

export function WorkStepsSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="WorkStepsSection">
        <div className="bgImg">
          <div></div>
        </div>

        <h3 className={"cloudTitle"}>
          <span>{t("Working Steps")}</span>
          {/* <img src={cloud} alt="cloud" /> */}
        </h3>

        <Container fluid>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={3} md={4} sm={6} xs={11}>
                <Card className={"shadow-sm"} data-aos="flip-left">
                  <img
                    src={cloudSmall}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h4>{t("1")}</h4>
                  <h5>{t("Identify your needs")}</h5>
                  <p>{t("workingSteps1")}</p>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={6} xs={11}>
                <Card className={"shadow-sm"} data-aos="flip-left">
                  <img
                    src={cloudSmall}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h4>{t("2")}</h4>
                  <h5>{t("Project Scheduling")}</h5>
                  <p>{t("workingSteps2")}</p>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={6} xs={11}>
                <Card className={"shadow-sm"} data-aos="flip-left">
                  <img
                    src={cloudSmall}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h4>{t("3")}</h4>
                  <h5>{t("Prototype")}</h5>
                  <p>{t("workingSteps3")}</p>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={6} xs={11}>
                <Card className={"shadow-sm"} data-aos="flip-left">
                  <img
                    src={cloudSmall}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h4>{t("4")}</h4>
                  <h5>{t("Launching")}</h5>
                  <p>{t("workingSteps4")}</p>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
}
export default WorkStepsSection;
