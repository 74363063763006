import React from "react";

import heroImage from "../assets/img/image 90.png";
import CircleSection from "../components/CircleSection/CircleSection";
import MissionVisionSection from "../components/MissionVisionSection/MissionVisionSection";
import HeroSection from "../components/HeroSection/HeroSection";
import img from "../assets/img/image 57.png";
import imgtwo from "../assets/img/image 56.png";
import defaultImg from "../assets/img/default.jpg";
import ProjectsSection from "../components/ProjectsSection/ProjectsSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
export function OurProjectsPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - "+ t("Our applications")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection
        title={"Our applications"}
        body={"lorem" }
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents activePage="Applications" />
      <ProjectsSection />
    </>
  );
}
export default OurProjectsPage;
