import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./BasicSection.scss";
import lineImage from "../../assets/svg/shaps/line 6.svg";

interface BasicSectionPropTypes {
  title: string;
  line?: boolean;
  body: string;
  link?: string;
  backgroundImg?: string;
  image?: string;
  textColor?: string;
  backgroundColor?: string;
  reverse?: boolean;
  dataAosFadeRight?: string;
  dataAosFadeLeft?: string;
  headTitle?: string;
}
const BasicSection: React.FC<BasicSectionPropTypes> = ({
  title,
  line = true,
  body,
  link,
  image,
  backgroundImg,
  textColor,
  backgroundColor,
  reverse = false,
  dataAosFadeRight = 'fade-right',
  dataAosFadeLeft = 'fade-left',
  headTitle = '',
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: +768,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section
        className="basicSection px-0"
        style={
          backgroundImg || backgroundColor
            ? {
              backgroundImage: `url('${backgroundImg}')`,
              backgroundColor: backgroundColor,
            }
            : {}
        }
      >
        <Container>
          <Row
            className={
              reverse
                ? "justify-content-center align-items-center p-0 flex-row-reverse"
                : "justify-content-center align-items-center p-0"
            }
          >
            <Col lg={6} xs={12} data-aos={dataAosFadeRight}>
              <Card className="border-0 bg-transparent p-md-5 p-xs-0" >
                {title && <h4 className="p-0" style={textColor ? { color: textColor } : {}}>{title}</h4>}
                {line && <img src={lineImage} className="line" alt="cloud" />}
                {/* {body && <p style={textColor ? { color: textColor } : {}}>{body}</p>} */}
                {body && <p style={textColor ? { color: textColor } : {}} id="clamp_15" dangerouslySetInnerHTML={{ __html: body}}/>}
              </Card>
            </Col>
            <Col
              lg={6}
              xs={12}
              className={
                !reverse ? "d-flex justify-content-end p-0" : "d-flex  p-0"
              }
              data-aos={dataAosFadeLeft}
            >
              <div className="imageContainer">
                {headTitle && <h5 style={{ textAlign: "center" }} className="p-0 m-0">{headTitle}</h5>}
                {image && (
                  <img
                    src={image}
                    alt="cloud"
                    className="img-fluid d-block m-auto border-radius"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default BasicSection;
