import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./ServiceCard.scss";
import lineImage from "../../assets/svg/shaps/line 6.svg";
import arrow from "../../assets/png/icons/hand.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface ServiceCardPropTypes {
  title: string;
  line?: boolean;
  body: string;
  link?: string;
  backgroundImg?: string;
  image?: string;
  textColor?: string;
  backgroundColor?: string;
  reverse?: boolean;
}
const ServiceCard: React.FC<ServiceCardPropTypes> = ({
  title,
  line = true,
  body,
  link,
  image,
  backgroundImg,
  textColor,
  backgroundColor,
  reverse = false,
}) => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: +768,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="serviceCard px-0" data-aos="zoom-in-up">
        <Container
          style={
            reverse
              ? {
                  backgroundColor: "#CCECff",
                  borderTopRightRadius: "500px",
                  borderBottomRightRadius: "500px",
                }
              : {
                  backgroundColor: "var(--primary)",
                  borderTopLeftRadius: "500px",
                  borderBottomLeftRadius: "500px",
                }
          }
        >
          <Row
            className={
              reverse
                ? "justify-content-center align-items-center p-0 flex-row-reverse"
                : "justify-content-center align-items-center p-0"
            }
          >
            <Col xl={8} lg={7} xs={12}>
              <Card className="border-0 bg-transparent p-5">
                {title && (
                  <h4 style={textColor ? { color: textColor } : {}}>{title}</h4>
                )}
                {line && <img src={lineImage} className="line" alt="cloud" />}
                {body && (
                  <p style={textColor ? { color: textColor } : {}}>{body}</p>
                )}
                {link && (
                  <Col xs={12} className="px-5 py-4">
                    <NavLink
                      to={"/services/" + link}
                      className={"shadow-sm bg-white p-3"}
                      style={{ borderRadius: "10px" }}
                    >
                      {t("more details")}
                      <img
                        src={arrow}
                        alt=""
                        className="mx-2"
                        style={{ width: "18px" }}
                      />
                    </NavLink>
                  </Col>
                )}
              </Card>
            </Col>
            <Col
              xl={4}
              lg={5}
              xs={12}
              className={
                !reverse ? "d-flex justify-content-end p-0" : "d-flex  p-0"
              }
            >
              <div className="imageContainer">
                {image && (
                  <img
                    src={image}
                    alt="cloud"
                    className="img-fluid d-block m-auto"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ServiceCard;
