import React from "react";
import { useTranslation } from "react-i18next";
import LandingSection from "../components/LandingSection/LandingSection";
import OurApplicationsSection from "../components/OurApplicationsSection/OurApplicationsSection";
import ServicesSection from "../components/ServicesSection/ServicesSection";
import SquareImageSection from "../components/SquareImageSection/SquareImageSection";
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import WorkStepsSection from "../components/WorkStepsSection/WorkStepsSection";

export function HomePage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - "+ t("Home")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <LandingSection />
      <SquareImageSection />
      <ServicesSection />
      <WorkStepsSection />
      <OurApplicationsSection />
      <WhyChooseUs />
    </>
  );
}
export default HomePage;
