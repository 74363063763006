import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./WhyChooseUs.scss";
import saudiIllustrat from "../../assets/img/hex.png";
import saudi from "../../assets/img/Group 167.png";
import { useTranslation } from "react-i18next";
export function WhyChooseUs() {
  const { t } = useTranslation();
  return (
    <>
      <section id="WhyChooseUs">
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col lg={6} md={12}>
              <Card>
                <h3 className={"cloudTitle bg-transparent"}>{t("Why Choose us")}</h3>
                <p data-aos="fade-left" className="my-2">
                {t("whyUSBody1")} 
                </p>
                <p data-aos="fade-left" className="my-2">
                {t("whyUSBody2")} 
                </p>
                <p data-aos="fade-left" className="my-2">
                {t("whyUSBody3")} 
                </p>
     
              </Card>
            </Col>
            <Col lg={6} md={12} className="img-whyUs p-0">
              <img src={saudiIllustrat} alt="cloud" className="" />
              {/* <img data-aos="fade-right" src={saudi} alt="cloud" className="" /> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default WhyChooseUs;
