import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./ProjectsSection.scss";
import app1 from "../../assets/png/shaps/app1.png";
import app2 from "../../assets/png/shaps/app2.png";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/png/icons/hand.png";
import { useTranslation } from "react-i18next";
export function ProjectsSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="projectsSection">
        <Container>
          <Row className="justify-content-center align-items-center">
            {/* Project Card */}
            <Col xs={12} className="mb-5">
              <Card>
                <h6 className={"projectBadge"}>{t("HR System")}</h6>
                <Row className="justify-content-center align-items-center">
                  <Col xl={3} lg={4} md={5} sm={10} xs={12}>
                    <div className="imgContainer">
                      <img src={app2} alt="cloud" className="img-fluid" />
                    </div>
                  </Col>

                  <Col xl={9} lg={8} md={6} sm={10} xs={12}>
                    <Row>
                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("name")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("Kader")}</p>
                      </Col>

                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("Service")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("HR System")}</p>
                      </Col>
                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("details")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("kaderBody")}</p>
                      </Col>
          
                      <Col xs={12} style={{display : "inline-grid", justifyContent : "flex-end"}}>
                        <NavLink to="/kader" className={"shadow-sm"}>
                          {t("more details")}{" "}
                          <img
                            src={arrow}
                            alt=""
                            className="mx-2"
                            style={{ width: "18px" }}
                          />
                        </NavLink>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Project Card */}
            <Col xs={12} className="my-5">
              <Card>
                <h6 className={"projectBadge"}>{t("ERP System")}</h6>
                <Row className="justify-content-center align-items-center">
                  <Col xl={3} lg={4} md={5} sm={10} xs={12}>
                    <div className="imgContainer">
                      <img src={app1} alt="cloud" className="img-fluid" />
                    </div>
                  </Col>

                  <Col xl={9} lg={8} md={6} sm={10} xs={12}>
                    <Row>
                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("name")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("Qarat")}</p>
                      </Col>

                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("Service")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("ERP System")}</p>
                      </Col>
                      <Col lg={2} md={3} xs={4}>
                        <p className="text-center">
                          <strong>{t("details")}</strong>
                        </p>
                      </Col>
                      <Col lg={10} md={9} xs={8}>
                        <p>{t("qeratBody")}</p>
                      </Col>
                      <Col xs={12} style={{display : "inline-grid", justifyContent : "flex-end"}}>
                        <NavLink to="/kerat" className={"shadow-sm"}>
                          {t("more details")}{" "}
                          <img
                            src={arrow}
                            alt=""
                            className="mx-2"
                            style={{ width: "18px" }}
                          />
                        </NavLink>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ProjectsSection;
