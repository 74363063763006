import React from "react";
import { useTranslation } from "react-i18next";

import ContactUsSection from "../components/ContactUsSection/ContactUsSection";
export function ContactUsPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - "+ t("Contact us")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <ContactUsSection />
    </>
  );
}
export default ContactUsPage;
