import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./App.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import HomePage from "./pages/HomePage";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import NavbarComponent from "./components/NavbarComponent/NavbarComponent";
import AboutUsPage from "./pages/AboutUsPage";
import BlogsPage from "./pages/BlogsPage";
import BlogsFinancialConsulting from "./pages/BlogsFinancialConsulting";
import OurProjectsPage from "./pages/OurProjectsPage";
import KaderPage from "./pages/KaderPage";
import KeratPage from "./pages/KeratPage";
import ErrorPage404 from "./pages/ErrorPage404";
import ServicesPage from "./pages/ServicesPage";
import heroImage from "./assets/img/image 69.png";
import HeroSection from "./components/HeroSection/HeroSection";
import ContactUsPage from "./pages/ContactUsPage";
import CareersPage from "./pages/CareersPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

import ErpSystemPage from "./pages/services/ErpSystemPage";
import DesignProgrammingPage from "./pages/services/DesignProgrammingPage";
import HRSystemPage from "./pages/services/HRSystemPage";
import ECOSystemPage from "./pages/services/ECOSystemPage";
import MobileAppPage from "./pages/services/MobileAppPage";
import POSSystemPage from "./pages/services/POSSystemPage";
import SPAppPage from "./pages/services/SPAppPage";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";

function App() {
  AOS.init();
  React.useEffect(() => {
    
    AOS.init({
      duration : 1500
    });
  }, []);
  return (
    <Router>
      <NavbarComponent />

      {/* <WhatsAppButton /> */}


      <Switch>
        <Route path="/our-projects" exact component={OurProjectsPage} />
        <Route path="/kader" exact component={KaderPage} />
        <Route path="/kerat" exact component={KeratPage} />
        <Route path="/about-us" exact component={AboutUsPage} />
        <Route path="/blogs" exact component={BlogsPage} />
        <Route path="/blogs/financial-consulting/:id" exact component={BlogsFinancialConsulting} />
        <Route path="/contact-us" exact component={ContactUsPage} />
        <Route path="/services" exact component={ServicesPage} />
        <Route path="/careers" exact component={CareersPage} />
        <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />

        {/* Services */}
  
 
        
        <Route path="/services/e-commerce" component={ECOSystemPage} />
        <Route path="/services/mobile-applications" component={MobileAppPage} />
        <Route path="/services/design-programming" component={DesignProgrammingPage} />
        <Route path="/services/erp-system" component={ErpSystemPage} />
        <Route path="/services/hr-system" component={HRSystemPage} />
        <Route path="/services/pos-system" component={POSSystemPage} />
        <Route path="/services/special-applications" component={SPAppPage} />



        {/* Services */}

        <Route path="/" exact component={HomePage} />
        <Route path="*" component={ErrorPage404} />
      </Switch>
      <FooterComponent />
    </Router>
  );
}

export default App;
