import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./StepsSection.scss";
import lineImage from "../../assets/png/shaps/line 1.png";
import dashLineImage from "../../assets/png/shaps/Line 18.png";
import dashCircleLineImage from "../../assets/png/shaps/dach line with ellips.png";
import { useTranslation } from "react-i18next";

interface StepsSectionPropTypes {
  title: string;
  line?: boolean;
  steps: any;
  backgroundColor?: string;
}
const StepsSection: React.FC<StepsSectionPropTypes> = ({
  title,
  line = true,
  steps,
  backgroundColor,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="stepsSection px-0"
        style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
      >
        <hr className="absoluteLine" />
        {/* <hr className="absoluteLine" /> */}
        <Container>
          {title && <h4>{title}</h4>}
          {line && <img src={lineImage} className="line" alt="cloud" />}
          {steps?.map((step: any, index: number) => (
            <>
              <Row className={"justify-content-around align-items-center p-0"}>
                <Col xl={5} lg={6} md={8} xs={12} className="position-relative">
                  {step.stepBody && (
                    <>
                      {steps.length !== index + 1 && (
                        <img
                          src={dashLineImage}
                          className="img-fluid dashLineImage"
                          alt={step.stepTitle}
                        />
                      )}
                    </>
                  )}

                  <img
                    src={dashCircleLineImage}
                    className="img-fluid dashCircleLineImage"
                    alt={step.stepTitle}
                  />
                  {step.stepTitle && (
                    <h6 data-aos="zoom-in-right" className="mx-2">{t(step.stepTitle)}</h6>
                  )}
                  {step.stepBody && (
                    <p data-aos="zoom-in-right" className="mx-2">{t(step.stepBody)}</p>
                  )}
                </Col>
                <Col md={4} xs={12}>
                  <div className="imageContainer">
                    {step.image && (
                      <img
                        src={step.image}
                        className="img-fluid"
                        alt={step.stepTitle}
                        data-aos="zoom-in-right"
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ))}
        </Container>
      </section>
    </>
  );
};
export default StepsSection;
