import React from "react";

import heroImage from "../assets/img/image 93.png";
import elipse from "../assets/img/box-elips.png";
import BasicSection from "../components/BasicSection/BasicSection";

import HeroSection from "../components/HeroSection/HeroSection";
import img1 from "../assets/img/Online Customer Service 1.png";
import img2 from "../assets/img/image 89.png";
import ProjectsSection from "../components/ProjectsSection/ProjectsSection";
import defaultImg from "../assets/img/default.jpg";
import CircleSection from "../components/CircleSection/CircleSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";

import { useTranslation } from "react-i18next";
import KaderCardsSection from "../components/KaderCardsSection/KaderCardsSection";
export function KaderPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - "+ t("Kader")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection title={"Kader"} body={" "} backgroundImg={heroImage} />
      <BreadcrumbsComponents
        parent="Applications"
        parentLink="/our-projects"
        activePage="Kader"
      />
      <BasicSection
        title={t("who is kader")}
        body={t("kaderbody")}
        image={img1}
        reverse={false}
      />
      <KaderCardsSection />
      <CircleSection
        title={t("whyKader")}
        body={t("whyKaderBody")}
        image={img2}
        reverse={false}
      />
      {/* <CircleSection
        title={"لماذا تحتاج الي كادر و كيف يفيد عملك"}
        body={t("lorem")}
        image={img2}
        reverse={false}
      /> */}

      <HeroSection
        title={"Do not think too much"}
        body={""}
        linkText={"subscribe now"}
        linkOut={"https://kaderhr.com/en"}
        heightAuto={"auto"}
        backgroundImg={elipse}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />
    </>
  );
}
export default KaderPage;
