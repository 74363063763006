import React from "react";
import { Button, Col, Collapse, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./NavbarComponent.scss";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLang } from "../../context/LangState";
import closeIcon from "../../assets/png/icons/x.png";
import appIcon from "../../assets/png/icons/clarity_vmw-app-line.svg";
import homeIcon from "../../assets/png/icons/home.svg";
import serviceIcon from "../../assets/png/icons/carbon_edge-service.svg";
import aboutIcon from "../../assets/png/icons/medical-icon_i-information-us.svg";
import langIcon from "../../assets/png/icons/global2.png";
import closeIconActive from "../../assets/png/icons/hover/x.svg";
import aboutIconActive from "../../assets/png/icons/hover/medical-icon_i-information-us.svg";
import appIconActive from "../../assets/png/icons/hover/clarity_vmw-app-line.svg";
import homeIconActive from "../../assets/png/icons/hover/home.svg";
import serviceIconActive from "../../assets/png/icons/hover/carbon_edge-service.svg";
import langIconActive from "../../assets/png/icons/global.png";
import logo from "../../assets/logo.png";
import logoAr from "../../assets/logoAr.png";
interface MenuSidebarPropTypes {
  closeMenu: () => void;
}
const MenuSidebar: React.FC<MenuSidebarPropTypes> = ({ closeMenu }) => {
  const { t } = useTranslation();
  const { toggleLang, lang } = useLang();

  return (
    <>
      <section className="menu" data-aos={lang == "ar" ? "fade-left" : "fade-right"} data-aos-duration="500">
        <Button className={"btn "}>
          <img
            src={closeIcon}
            className="d-inline-block"
            onClick={closeMenu}
            alt=""
          />
        </Button>

        <NavLink
          to="/"
          exact
          className={"btn w-100"}
          activeClassName="sideBarLinkActive"
          onClick={closeMenu}
        >
          <img src={homeIcon} alt="" />
          <img src={homeIconActive} alt="" />
          {t("Home")}
        </NavLink>

        <NavLink
          to="/services"
          exact
          className={"btn w-100"}
          activeClassName="sideBarLinkActive"
          onClick={closeMenu}
        >
          <img src={serviceIcon} alt="" />
          <img src={serviceIconActive} alt="" />
          {t("Services")}
        </NavLink>

        <NavLink
          to="/about-us"
          exact
          className={"btn w-100"}
          activeClassName="sideBarLinkActive"
          onClick={closeMenu}
        >
          <img src={aboutIcon} alt="" />
          <img src={aboutIconActive} alt="" />
          {t("About us")}
        </NavLink>

        <NavLink
          to="/our-projects"
          exact
          className={"btn w-100"}
          activeClassName="sideBarLinkActive"
          onClick={closeMenu}
        >
          <img src={appIcon} alt="" />
          <img src={appIconActive} alt="" />
          {t("Applications")}
        </NavLink>

        <Button className={"btn w-100"} onClick={toggleLang}>
          <img src={langIcon} alt="" />
          {t("English")}
        </Button>

        {lang == "ar" ? (
          <img src={logoAr} className="logoSideBar" alt="cloud secrets" />
        ) : (
          <img src={logo} className="logoSideBar" alt="cloud secrets" />
        )}
      </section>
    </>
  );
};
export default MenuSidebar;
