import React from "react";

import heroImage from "../assets/img/image 87.png";
import erpImg from "../assets/img/image 44.webp"; 
import ecoImg from "../assets/img/image 58.webp"; 
import progImg from "../assets/img/image 42.jpg"; 
import mobImg from "../assets/img/photo-1586717799252-bd134ad00e26.avif"; 
import spImg from "../assets/img/photo-1545235617-7a424c1a60cc.avif"; 
import posImg from "../assets/img/photo-1556740714-a8395b3bf30f.avif"; 
import hrImg from "../assets/img/photo-1544725121-be3bf52e2dc8.avif"; 
import HeroSection from "../components/HeroSection/HeroSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import { useTranslation } from "react-i18next";
export function ServicesPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - "+ t("Services")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={t("Services")}
        body={t("servicesBody")}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents activePage="Services" />
      <ServiceCard
        title={t("(ERP System)Enterprise Resource Planning")}
        body={t("erpSystem")}
        image={erpImg}
        reverse={false}
        link="erp-system"
      />
      <ServiceCard
        title={t("website design and programming")}
        body={t("websiteDevelopment")}
        image={progImg}
        reverse={true}
        link="design-programming"
      />
      <ServiceCard
        title= {t("E-Commerce System")}
        body={t("eCommerceSystems")}
        image={ecoImg}
        reverse={false}
        link="e-commerce"
      />
      <ServiceCard
        title={t("Design and Programming of Mobile Applications")}
        body={t("mobileApplications")}
        image={mobImg}
        reverse={true}
        link="mobile-applications"
      />
      <ServiceCard
        title={t("Point of Sale (POS) System")}
        body={t("posbody")}
        image={posImg}
        reverse={false}
        link="pos-system"
      />
      <ServiceCard
        title=  {t("HR System")}
        body={t("hrBody")}
        image={hrImg}
        reverse={true}
        link="hr-system"
      />
        <ServiceCard
          title={t("Design and programming of special applications")}
          body={t("customizedApplicationsBody")}
          image={spImg}
          reverse={false}
          link="special-applications"
        />
    </>
  );
}
export default ServicesPage;
