import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./OurApplicationsSection.scss";
import cloudSmall from "../../assets/png/shaps/cloud small.png";
import cloud from "../../assets/png/shaps/cloud big.png";
import app1 from "../../assets/png/shaps/app1.png";
import app2 from "../../assets/png/shaps/app2.png";
import ourProjectsLines from "../../assets/png/shaps/ourProjectsLines.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function OurApplicationsSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="ourApplicationsSection">
        <h3 className={"cloudTitle"}>
          <span>{t("Our applications")}</span>
          <img src={cloud} alt="cloud" />
        </h3>

        <Container>
          <img
            src={ourProjectsLines}
            alt="cloud"
            className="ourProjectsLines"
          />
          <Row className="justify-content-center align-items-center">
            <Col md={3} sm={6} xs={12} data-aos="zoom-in">
              <Card className={"bg-primary-heavy p-3 my-5"}>
                <p className="text-center m-0 text-white">
                  {t(
                    "Why we have helped more than 270 users around the Kingdom"
                  )}
                </p>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-around align-items-center">
            <Col lg={4} md={6} sm={12} xs={12} data-aos="zoom-in">
              <NavLink to="/kader">
                <Card className={"bg-primary my-5"}>
                  <img
                    src={app2}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h5 className="text-center text-white">{t("Kader")}</h5>
                  <p className="text-center text-white">{t("kaderCardBody")}</p>
                </Card>
              </NavLink>
            </Col>

            <Col lg={4} md={6} sm={12} xs={12} data-aos="zoom-in">
              <NavLink to="/kerat">
                <Card className={"bg-primary p-3 my-5"}>
                  <img
                    src={app1}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />

                  <h5 className="text-center text-white">{t("Qarat")}</h5>
                  <p className="text-center text-white">{t("QaratCardBody")}</p>
                </Card>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default OurApplicationsSection;
