import React from "react";

import heroImage from "../../assets/img/image 69.png";
import sec2Image from "../../assets/img/bgdark.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section3 from "../../assets/img/image 58.webp";
import section1 from "../../assets/png/shaps/mobb.png";
import BasicSection from "../../components/BasicSection/BasicSection";
import StepsImg1 from "../../assets/png/shaps/Group 122.png";
import StepsImg2 from "../../assets/png/shaps/Group 121.png";
import StepsImg3 from "../../assets/png/shaps/Group 120.png";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
import StepsSection from "../../components/StepsSection/StepsSection";
import CircleSection from "../../components/CircleSection/CircleSection";
export function SPAppPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"Design and programming of special applications"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="Design and programming of special applications"
      />
      {/* <BasicSection
        title={t("If you want a custom-made mobile app for your customers You are in the right place")}
        body={t(" ")}
        image={sec2Image}
        backgroundColor="#1e306b"
        textColor="#fff"
        reverse={false}
      /> */}

      <HeroSection
        title={"If you want a custom-made mobile app for your customers You are in the right place"}
        body={""}
        heightAuto={"350px"}
        backgroundImg={sec2Image}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />

      <BasicSection
        title={t("mobSection1Title1")}
        body={t("customizedApplicationsBody")}
        image={section1}
        reverse={false}
      />
      <StepsSection
        title={t("websiteServiceSection2Title")}
        steps={[
          {
            stepTitle: t("Design the user interface and user experience"),
            image: StepsImg1,
          },
          {
            stepTitle: t(
              "Application development and creation on high-resolution servers"
            ),
            image: StepsImg2,
          },
          {
            stepTitle: t("Improve your old apps and make them more friendly"),
            image: StepsImg3,
          },
        ]}
        backgroundColor="#313D5E"
      />
      <CircleSection
        title={t("Why Choose us")}
        body={t("whyUSBody")}
        image={section3}
        reverse={false}
      />
    </>
  );
}
export default SPAppPage;
