import React from "react";
import { useTranslation } from "react-i18next";

export const LangContext = React.createContext({
  lang: "",
  toggleLang: () => {},
});

interface LangStateProps {
  children: React.ReactNode;
}

const LangProvider: React.FC<LangStateProps> = ({ children }) => {
  const [lang, setLang] = React.useState("");
  const { i18n } = useTranslation();

  const toggleLang = () => {
    
    if (lang === "ar") {
      setLang("en");
      localStorage.setItem("i18nextLng", 'en');
    } else {
      setLang("ar");
      localStorage.setItem("i18nextLng", 'ar');
    }
  };

  const checkLang = () => {
    const currLang = localStorage.getItem("i18nextLng");
    if (currLang) {
      setLang(currLang);
    }
  };

  React.useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", lang == "ar" ? "rtl" : "ltr");
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", lang == "ar" ? "direction : rtl" : "direction : ltr");
    i18n.changeLanguage(lang);
  }, [lang]);

  React.useEffect(() => {
    checkLang();
  }, []);

  return (
    <LangContext.Provider value={{ lang, toggleLang }}>
      {children}
    </LangContext.Provider>
  );
};


const useLang = () => {
  const context = React.useContext(LangContext)
  if (context === undefined) {
    throw new Error('useLang must be used within a CountProvider')
  }
  return context;
}

export {LangProvider, useLang};
