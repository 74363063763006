import React from "react";

import heroImage from "../../assets/img/image 83.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section2 from "../../assets/png/shaps/website.png";
import elipse from "../../assets/img/box-elips.png";
import BasicSection from "../../components/BasicSection/BasicSection";
import defaultImg from "../../assets/img/default.jpg";
import comp from "../../assets/png/shaps/comp.png";
import dachord from "../../assets/svg/vectors/dachord.svg";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
import ManyCards from "../../components/ManyCards/ManyCards";
export function HRSystemPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection title={"HR System"} body={" "} backgroundImg={heroImage} />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="HR System"
      />

      <BasicSection
        title={t("hrSection1Title1")}
        body={t("hrSection1Body1") + " " + t("hrSection1Body2")}
        image={comp}
        backgroundColor="#CCECFF"
        reverse={false}
      />

      <ManyCards />

      <HeroSection
        title={"Leave the past and join..... with us towards the future"}
        body={""}
        heightAuto={"150px"}
        backgroundImg={elipse}
        backgroundAttachment={"initial"}
        mouseScroll={false}
      />
    </>
  );
}
export default HRSystemPage;
