import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./SquareImageSection.scss"
import dachord from "../../assets/img/devices.png"
export function SquareImageSection() {
    return (
        <>
            <section id="SquareImageSection">
                <Container className="p-0">
                    <Row className="justify-content-center">
                        <Col lg={9}  xs={12}>
                            <img src={dachord}
                                className="img-fluid d-block m-auto" alt="cloud" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default SquareImageSection;
