import React from "react";
import { Link } from 'react-router-dom';
import heroImage from "../assets/img/image 93.png";
import BasicSection from "../components/BasicSection/BasicSection";
import HeroSection from "../components/HeroSection/HeroSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GrDocumentText } from "react-icons/gr";
import axios from "axios";
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { CiSearch } from "react-icons/ci";


import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";

export default function BlogsPage() {

  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "en";

  interface Category {
    id: number;
    name: string;
  }
  interface Data {
    id: number;
    image: string;
    title_ar: string;
    title_en: string;
    description_ar: string;
    description_en: string;
    short_description_ar: string;
    short_description_en: string;
    category_id: number;
    name_ar: string;
    name_en: string;
    active: number;
    add_date: string;
  }

  interface ItemData {
    data: Data[];
  }

  interface BlogData {
    categories: Category[];
    items: ItemData;
  }

  // const [blogs, setBlogs] = React.useState<BlogData>({ items: [], categories: [] });
  const [blogs, setBlogs] = React.useState<BlogData>({ items: { data: [] }, categories: [] });

  const [search, setSearch] = React.useState<string>('');
  const [categoryId, setCategoryId] = React.useState<number>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [placeHolder, setPlaceHolder] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const itemsPerPage = 5000;

  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - " + t("Blog")
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [t]);

  // let setTimeoutGetData= null;
  // React.useEffect(() => {
  //   clearTimeout('setTimeoutGetData')
  //   setTimeoutGetData = setTimeout(()=>{
  //     getBlogs()
  //   },1000)
  // }, [categoryId,currentPage]);

  let setDataTimeout: any;
  React.useEffect(() => {
    clearTimeout(setDataTimeout)
    setDataTimeout = setTimeout(() => {
      getBlogs()
    }, 1000);
  }, [categoryId, currentPage]);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    if (scrollY + windowHeight >= documentHeight - 300) {
      setCurrentPage(currentPage + 1);
    }
  };

  // React.useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [currentPage]);


  const endPoint = 'https://api.cloudsecretsksa.com' /* https://cloudsecrets-api.cs-testing.com */
  const token = 'Bearer sFeWbKwiBcO5Fbw22lMzVgK9UWL2jDPY57iAT14zuzfeyfCq1vVUYSYNpekQGlMTg2CUV6ZOH4vfiJ8EMjLps6GvLlHikIvOw9DUO8YvSPQF4l6hwWf9QqEr6nv4Feq2xGM7fbTwnkDdkTaCsJZGPSzQkLkafZC0j4k8QtjRe9ReSRc2ntpJm4mlozaHI2cz4jpbEgvd'
  const getBlogs = () => {
    (search || categoryId) && setPlaceHolder(true)
    let params = `?rows=${itemsPerPage}&page=${currentPage}&word=${search || ''}&category_id=${categoryId || ''}`
    // axios.get(`${endPoint}/api/v1/${lang}/front/blogs?category_id=${categoryId || ''}`, {
    axios.get(`${endPoint}/api/v1/${lang}/front/blogs${params}`, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      console.log('response', response.data);
      setPlaceHolder(false)
      setLoading(false)
      if (response.data.check) {
        setBlogs(response.data.data)
      }
    }).catch((error) => {
      setPlaceHolder(false)
      setLoading(false)
      console.log(error);
    });
  }

  return (
    <section>
      <HeroSection title={"The Blog"} body={" "} backgroundImg={heroImage} />
      <BreadcrumbsComponents activePage="Blog" />

      <Container className="px-5" fluid>

        <Row>

          {blogs?.items.data?.length ?
            <Col sm={12} className="my-4" >
              <Row className="justify-content-end">
                <Col sm={3}  >
                  <InputGroup dir="ltr">
                    <InputGroup.Text id="inputGroup-sizing-lg" onClick={() => search?.trim()?.length && getBlogs()} style={{ cursor: 'pointer' }} ><CiSearch /></InputGroup.Text>
                    <Form.Control dir="rtl"
                      type="search"
                      name="search"
                      onChange={(e: any) => setSearch(e.target.value)}
                      aria-describedby="inputGroup-sizing-lg"
                      placeholder={t("search")}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            : ''}

          <Col sm={3} >
            <ListGroup >
              {blogs?.categories?.length ? blogs?.categories.map((category, index) => (
                <ListGroup.Item action key={category.id} onClick={() => setCategoryId(category.id)}>
                  <GrDocumentText />
                  <span className="mx-2">{category.name}</span>
                </ListGroup.Item>
              )) :
                ''}
            </ListGroup>
          </Col>

          {loading &&
            <Col sm={blogs?.categories?.length ? 9 : 12} >
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', textAlign: 'center', height: '40dvh' }}>
                <div><Spinner animation="grow" /></div>
                <div> {t("loading data")}</div>
              </div>
            </Col>
          }

          {placeHolder ?
            <Col sm={9} className="mt-5">
              <Row style={{ gap: '15px' }} >
                <Col sm={5}>
                  <Placeholder animation="glow" >
                    <Placeholder xs={12} style={{ height: '50dvh', borderRadius: '10px' }} />
                  </Placeholder>
                </Col>
                <Col sm={5} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                  <Placeholder animation="glow" className='d-flex' style={{ flexDirection: 'column', alignContent: 'center', gap: '8px' }} >
                    <Placeholder xs={12} />
                    <Placeholder xs={12} />
                    <Placeholder xs={12} />
                    <Placeholder xs={10} />
                    <Placeholder xs={8} />
                    <Placeholder xs={6} />
                  </Placeholder>
                </Col>
              </Row>
              <Row style={{ gap: '15px' }} className="my-5">
                <Col sm={5} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                  <Placeholder animation="glow" className='d-flex' style={{ flexDirection: 'column', alignContent: 'center', gap: '8px' }} >
                    <Placeholder xs={12} />
                    <Placeholder xs={12} />
                    <Placeholder xs={12} />
                    <Placeholder xs={10} />
                    <Placeholder xs={8} />
                    <Placeholder xs={6} />
                  </Placeholder>
                </Col>
                <Col sm={5}>
                  <Placeholder animation="glow" >
                    <Placeholder xs={12} style={{ height: '50dvh', borderRadius: '10px' }} />
                  </Placeholder>
                </Col>
              </Row>
            </Col>
            :
            <Col sm={9}>
              {blogs?.items.data?.length ?
                blogs?.items.data.map((item, index) => (
                  <Link key={item.id} to={`/blogs/financial-consulting/${item.id}`}>
                    <BasicSection
                      key={item.id}
                      title={t(lang === 'ar' ? item.title_ar : item.title_en)}
                      body={t(lang === 'ar' ? item.description_ar : item.description_en)}
                      image={endPoint + item.image}
                      reverse={index % 2 === 0}
                    />
                  </Link>
                )) :
                !loading ?
                  <div style={{ height: '50dvh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} className="mt-4" >
                    <Alert variant='primary'>{t('no data')}</Alert>
                  </div>
                  : ''
              }
            </Col>
          }

        </Row>
      </Container>
    </section>
  );
}
