import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./ManyCards.scss";
import { useTranslation } from "react-i18next";

import card1Icon from "../../assets/png/icons/system-ok.png";
import card1IconActive from "../../assets/png/icons/hover/system-ok.png";
import card2Icon from "../../assets/png/icons/translation.png";
import card2IconActive from "../../assets/png/icons/hover/translation.png";
import card3Icon from "../../assets/png/icons/money.png";
import card3IconActive from "../../assets/png/icons/hover/money.png";
import card4Icon from "../../assets/png/icons/notification.png";
import card4IconActive from "../../assets/png/icons/hover/notification.png";
import card5Icon from "../../assets/png/icons/run-circle.png";
import card5IconActive from "../../assets/png/icons/hover/run-circle.png";
import card6Icon from "../../assets/png/icons/vecation.png";
import card6IconActive from "../../assets/png/icons/hover/vecation.png";
import card7Icon from "../../assets/png/icons/rate.png";
import card7IconActive from "../../assets/png/icons/hover/rate.png";
import card8Icon from "../../assets/png/icons/sheak.png";
import card8IconActive from "../../assets/png/icons/hover/sheak.png";
import card9Icon from "../../assets/png/icons/docs.png";
import card9IconActive from "../../assets/png/icons/hover/docs.png";
export function ManyCards() {
    const { t } = useTranslation();
  return (
    <>
      <section className="manyCards">
        <div className="bgImg">
          <div></div>
        </div>

        <h3 className={"cloudTitle"}>
          <span>{t("HR Services")}</span>
          {/* <img src={cloud} alt="cloud" /> */}
        </h3>

        <Container fluid>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card1Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card1IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Certified system")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card2Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card2IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("multilingual")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card3Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card3IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Payroll preparation")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card4Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card4IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Alerts and notifications")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card5Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card5IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Attendance and Departure")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card6Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card6IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Leaves and absences")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card7Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card7IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Staff evaluation")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card8Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card8IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("barrow payment of the staff")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>
              <Col  md={4} sm={6} xs={11} data-aos="fade-right">
                <Card >
                  <img
                    src={card9Icon}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <img
                    src={card9IconActive}
                    alt="cloud"
                    className={"img-fluid d-block m-auto"}
                  />
                  <h6>{t("Document Templates")}</h6>
                  {/* <p>{t("lorem")}</p> */}
                </Card>
              </Col>

            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
}
export default ManyCards;
