import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./ServicesSection.scss";
import cloudBig from "../../assets/png/vector/cloud big.svg";
import erpicon from "../../assets/png/icons/erp icon.png";
import erpiconhover from "../../assets/png/icons/hover/erp icon.png";
import eCommerce from "../../assets/png/icons/eCommerce.png";
import eCommercehover from "../../assets/png/icons/hover/eCommerce.png";
import mopileIcon from "../../assets/png/icons/mopileIcon.png";
import mopileIconhover from "../../assets/png/icons/hover/mopile icon.png";
import webdevelopment from "../../assets/png/icons/web development.png";
import webdevelopmenthover from "../../assets/png/icons/hover/web development.png";
import appset from "../../assets/png/icons/app set.png";
import appsethover from "../../assets/png/icons/hover/app set.png";
import hricongroup from "../../assets/png/icons/hricongroup.png";
import hricongrouphover from "../../assets/png/icons/hover/hr icon group.png";
import pos from "../../assets/png/icons/pos Icon.png";
import poshover from "../../assets/png/icons/hover/pos Icon.png";
import rain from "../../assets/png/shaps/rain.png";
import raintablat from "../../assets/png/shaps/raintablat.png";
import rainmobile from "../../assets/png/shaps/rainmobile.png";
import cloud from "../../assets/png/shaps/cloud big.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
export function ServicesSection() {
    const { t } = useTranslation();
    return (
        <>
            <section id="ServicesSection">
                <Container>
                <img src={rain} alt="cloud" className="rain" /> 
                <img src={raintablat} alt="cloud" className="raintablat" /> 
                <img src={rainmobile} alt="cloud" className="rainmobile" /> 
                    <h3 className={"cloudTitle"}>
                        <span>
                        {t("Services")}
                        </span>
                        <img src={cloud} alt="cloud" />
                    </h3>


                    <Row className="justify-content-center align-items-center">
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                            <NavLink to="/services/mobile-applications">
                            <Card>
                                <img src={mopileIcon}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={mopileIconhover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6>{t("Design and Programming of Mobile Applications")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                        <NavLink to="/services/e-commerce">
                            <Card>
                                <img src={eCommerce}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={eCommercehover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6> {t("E-Commerce System")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                        <NavLink to="/services/design-programming">
                            <Card>
                                <img src={webdevelopment}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={webdevelopmenthover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6> {t("website design and programming")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                        <NavLink to="/services/erp-system">
                            <Card>
                                <img src={erpicon}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={erpiconhover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6>{t("(ERP System)Enterprise Resource Planning")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">

                        <NavLink to="/services/special-applications">
                            <Card>
                                <img src={appset}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={appsethover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6>{t("Design and programming of special applications")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                        <NavLink to="/services/hr-system">
                            <Card>
                                <img src={hricongroup}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={hricongrouphover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6>  {t("HR System")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={10} data-aos="zoom-in">
                        <NavLink to="/services/pos-system">
                            <Card>
                                <img src={pos}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <img src={poshover}
                                alt="cloud" className={"img-fluid m-auto"} />
                                <h6>{t("Point of Sale (POS) System")}</h6>
                            </Card>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default ServicesSection;
