import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./CircleSection.scss";
import line from "../../assets/svg/shaps/line 6.svg";

interface CircleSectionPropTypes {
  title: string;
  body: string;
  link?: string;
  image?: string;
  reverse?: boolean;
}
const CircleSection: React.FC<CircleSectionPropTypes> = ({
  title,
  body,
  link,
  image,
  reverse = false,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: +768,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="circleSection px-0">
        <Container fluid className="p-0">
          <Row
            className={
              reverse
                ? "justify-content-center align-items-center p-0 flex-row-reverse"
                : "justify-content-center align-items-center p-0"
            }
          >
            <Col lg={6} xs={12} data-aos="fade-right">
              <Card className="border-0 p-5">
                <h4>{title}</h4>
                <img src={line} className="line" alt="cloud" />
                <p>{body}</p>
              </Card>
            </Col>
            <Col
              lg={6}
              xs={12}
              data-aos="fade-left"
              className={
                !reverse ? "d-flex justify-content-end p-0" : "d-flex  p-0"
              }
            >
              <div className="circle">
                <img src={image} alt="cloud" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default CircleSection;
