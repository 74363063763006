import React from "react";

import heroImage from "../assets/img/image 91.png";
import CircleSection from "../components/CircleSection/CircleSection";
import MissionVisionSection from "../components/MissionVisionSection/MissionVisionSection";
import HeroSection from "../components/HeroSection/HeroSection";
import img from "../assets/img/image 57.png";
import imgtwo from "../assets/img/image 56.png";
import defaultImg from "../assets/img/default.jpg";
import ab from "../assets/img/ab.jpeg";
import cloudS from "../assets/img/cloudS.png";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
export function AboutUsPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - " + t("About us");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection
        title={"About us"}
        backgroundColor={"rgba(0,0,0,0.5)"}
        body={"welcomeBody"}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents activePage="About us" />

      <CircleSection
        title={t("Who we are")}
        body={t("introBody")}
        image={imgtwo}
      />
      <CircleSection
        title={t("Why Choose us")}
        body={t("whyUSBody")}
        image={img}
        reverse={true}
      />
      <MissionVisionSection />
      {/* 
      <CircleSection
        title={t("cloud secrets")}
        body={t("seglTogary")}
        image={ab}
        reverse={true}
      /> */}

      <HeroSection
        title={t("cloud secrets")}
        body={t("seglTogary")}
        backgroundImg={cloudS}
        mouseScroll={false}
        heightAuto="500px"
      />
    </>
  );
}
export default AboutUsPage;
