import React from "react";

import heroImage from "../../assets/img/image 65.png";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import section2 from "../../assets/png/shaps/website.png";
import section3 from "../../assets/img/image 58.webp";
import BasicSection from "../../components/BasicSection/BasicSection";
import StepsSection from "../../components/StepsSection/StepsSection";
import StepsImg1 from "../../assets/png/shaps/website 2.png";
import StepsImg2 from "../../assets/png/shaps/website 3.png";
import StepsImg3 from "../../assets/png/shaps/website 2 screen search.png";
import CircleSection from "../../components/CircleSection/CircleSection";
import BreadcrumbsComponents from "../../components/BreadCrumbs/BreadCrumbs";
export function DesignProgrammingPage() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("Cloud Secrets");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeroSection
        title={"website design and programming"}
        body={" "}
        backgroundImg={heroImage}
      />
      <BreadcrumbsComponents
        parent="Services"
        parentLink="/services"
        activePage="website design and programming"
      />
      <BasicSection
        title={t("websiteServiceSection1Title")}
        body={t("websiteDevelopment")}
        image={section2}
        reverse={false}
      />
      <StepsSection
        title={t("websiteServiceSection2Title")}
        steps={[
          {
            stepTitle: t("websiteServiceSection2ListTitle1"),
            image: StepsImg1,
            stepBody: t("websiteServiceSection2ListBody1"),
          },
          {
            stepTitle: t("websiteServiceSection2ListTitle2"),
            image: StepsImg2,
            stepBody: t("websiteServiceSection2ListBody2"),
          },
          {
            stepTitle: t("websiteServiceSection2ListTitle3"),
            image: StepsImg3,
            stepBody: t("websiteServiceSection2ListBody3"),
          },
        ]}
        backgroundColor="#313D5E"
      />
      <CircleSection
        title={t("Why Choose us")}
        body={t("whyUSBody")}
        image={section3}
        reverse={false}
      />
    </>
  );
}
export default DesignProgrammingPage;
