import React, { useState } from "react";

import BasicSection from "../components/BasicSection/BasicSection";
import HeroSection from "../components/HeroSection/HeroSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import { Col, Row, Button, Spinner } from "react-bootstrap";

import { Link, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import axios from "axios";

export default function BlogsFinancialConsulting() {
  interface RouteParams {
    id: string;
  }
  interface Category {
    id: number;
    name: string;
    image: string;
    name_ar: string;
    name_en: string;
  }
  interface Data {
    id: number;
    image: string;
    title_ar: string;
    title_en: string;
    description_ar: string;
    description_en: string;
    short_description_ar: string;
    short_description_en: string;
    category_id: number;
    name_ar: string;
    name_en: string;
    active: number;
    add_date: string;
  }
  interface BlogData {
    items: Data[];
  }

  const { id } = useParams<RouteParams>();
  const [blogs, setBlogs] = React.useState<BlogData>({ items: [] });
  const [updatedId, setUpdatedId] = React.useState<number>();
  const [blogDetails, setBlogDetails] = React.useState<Data>({
    id: 0,
    image: '',
    title_ar: '',
    title_en: '',
    description_ar: '',
    description_en: '',
    short_description_ar: '',
    short_description_en: '',
    category_id: 0,
    name_ar: '',
    name_en: '',
    active: 0,
    add_date: '',
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "en";

  const dummyDataObject = {
    id: 1,
    image: require("../assets/svg/blog/1.svg").default,
    title_ar: "مفهوم الفاتورة الاليكترونية",
    title_en: "Electronic invoice concept",
    name_en: "Cat 1",
    name_ar: "فئة 1",
    description_ar: "Description in Arabic",
    description_en: "Description in English",
    htmlContent: `
    <div>
      <h2>ما هي المرحلة الثانية للفاتورة الإلكترونية؟</h2>
      <p>هي امتداد للمرحلة الأولى من الفاتورة الإلكترونية، وتسمى مرحلة الربط والتكامل، إذ تُربط أنظمة الفوترة الإلكترونية للشخص عبر منصة فاتورة بهيئة الزكاة والضريبة والجمارك. تطبق المرحلة الثانية على المكلف الذي تزيد إيراداته السنوية التي تخضع لضريبة القيمة المضافة عن نصف مليار ريال سعودي عام 2021م.</p>
      
      <h3>موعد تطبيق الفاتورة الإلكترونية للمرحلة الثانية</h3>
      <p>طُبقت المرحلة الثانية للفاتورة الإلكترونية بشكل مرحلي بدءًا من 1 يناير 2023، وسيتم تطبيقها حتى 31 ديسمبر عام 2023م، ولا بد من تصديرها بالصيغة المطلوبة.</p>
      
      <h3>الشركات المستهدفة بالمرحلة الثانية من الفاتورة الإلكترونية</h3>
      <p>كافة الشركات المكلفة بتطبيق المرحلة الأولى للفاتورة الإلكترونية، والتي تزيد إيراداتها عن نصف مليار ريال سعودي سنويًّا؛ مستهدفة بتطبيق المرحلة الثانية منها، وستُطبق على مجموعات وسيتم إخطارهم قبل الموعد الفعلي بستة أشهر.</p>
      
      <h3>متطلبات المرحلة الثانية من الفاتورة الإلكترونية السعودية</h3>
      <p>إذا كنت تتساءل عن متطلبات المرحلة الثانية للفوترة الإلكترونية؛ فإنه يوجد أربعة متطلبات رئيسة لا بد من توافرها للالتزام بتطبيق تلك المرحلة بما يتوافق مع الربط مع هيئة الزكاة والدخل، تلك المتطلبات هي ما يلي:</p>
      <ul>
        <li>تبسيط وتوحيد البيانات</li>
        <li>توفير التكاليف والأتمتة</li>
        <li>المتطلبات الأمنية للفواتير الإلكترونية</li>
        <li>إرشادات ولوائح الامتثال الضريبي</li>
      </ul>
    </div>`
  };

  // Dummy data array
  const dummyDataArray = [
    {
      id: 1,
      image: require("../assets/svg/blog/1.svg").default,
      title_ar: "مفهوم الفاتورة الاليكترونية",
      title_en: "Electronic invoice concept",
      description_ar: "Description in Arabic",
      description_en: "Description in English",
      name_en: "Cat 1",
      name_ar: "فئة 1",
    },
    {
      id: 2,
      image: require("../assets/svg/blog/2.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 2",
      name_ar: "فئة 2",
    },
    {
      id: 3,
      image: require("../assets/svg/blog/3.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 3",
      name_ar: "فئة 3",
    },
    {
      id: 4,
      image: require("../assets/svg/blog/4.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 4",
      name_ar: "فئة 4",
    },
    {
      id: 5,
      image: require("../assets/svg/blog/5.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 5",
      name_ar: "فئة 5",
    },
    {
      id: 6,
      image: require("../assets/svg/blog/6.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 6",
      name_ar: "فئة 6",
    },
    {
      id: 7,
      image: require("../assets/svg/blog/7.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 7",
      name_ar: "فئة 7",
    },
    {
      id: 8,
      image: require("../assets/svg/blog/8.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 8",
      name_ar: "فئة 8",
    },
    {
      id: 9,
      image: require("../assets/svg/blog/9.svg").default,
      title_ar: "مقالة ثانية",
      title_en: "Second Article",
      description_ar: "Description in Arabic for the second article",
      description_en: "Description in English for the second article",
      name_en: "Cat 9",
      name_ar: "فئة 9",
    },
  ];

  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - " + t("Blog")
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [t]);

  React.useEffect(() => {
    getBlogDetails()
    !blogs?.items?.length && getBlogs()
  }, [updatedId]);

  const endPoint = 'https://api.cloudsecretsksa.com'
  const token = 'Bearer sFeWbKwiBcO5Fbw22lMzVgK9UWL2jDPY57iAT14zuzfeyfCq1vVUYSYNpekQGlMTg2CUV6ZOH4vfiJ8EMjLps6GvLlHikIvOw9DUO8YvSPQF4l6hwWf9QqEr6nv4Feq2xGM7fbTwnkDdkTaCsJZGPSzQkLkafZC0j4k8QtjRe9ReSRc2ntpJm4mlozaHI2cz4jpbEgvd'
  const getBlogDetails = () => {
    setLoading(true)
    axios.get(`${endPoint}/api/v1/${lang}/front/get_blog/${updatedId || id}`, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      console.log('getBlogDetails', response.data.data.data);
      setLoading(false)
      if (response.data.check) {
        setBlogDetails(response.data.data.data)
      }
    }).catch((error) => {
      setLoading(false)
      console.log(error);
    });
  }

  const getBlogs = () => {
    // let params = '?rows=10&page=1&word=&category_id=4'
    axios.get(`${endPoint}/api/v1/${lang}/front/blogs`, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      console.log('response', response.data.data);
      if (response.data.check) {
        setBlogs(response.data.data)
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <section>

      <HeroSection title={lang === 'ar' ? blogDetails?.title_ar : blogDetails?.title_en}
        body={lang === 'ar' ? blogDetails?.name_ar : blogDetails?.name_ar} backgroundImg={endPoint + blogDetails?.image}
        backgroundRepeat="no-repeat" backgroundSize="cover" heightAuto="calc(50vh + 150px)" />

      <BreadcrumbsComponents activePage="Financial Consulting" parent="Blog" parentLink="/blogs" />

      <Row className="m-0">
        {loading ?
          <Col lg={9} xs={12} className="p-5">
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', textAlign: 'center', height: '40dvh' }}>
              <div><Spinner animation="grow" /></div>
              <div> {t("loading data")}</div>
            </div>
          </Col>
          :
          <Col lg={9} xs={12} className="p-5">
            <h3 className={readMore ? 'expanded' : 'line-clamp'} dangerouslySetInnerHTML={{ __html: lang === 'ar' ? blogDetails?.short_description_ar : blogDetails?.short_description_en }} />
            <p className={readMore ? 'expanded' : 'line-clamp'} dangerouslySetInnerHTML={{ __html: lang === 'ar' ? blogDetails?.description_ar : blogDetails?.description_en }} />
            <div className="mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={toggleReadMore}>{readMore ? t("Hide") : t("Read more")}</Button>
            </div>
          </Col>
        }

        <Col lg={3} xs={12} className="px-5" style={{ overflowY: 'scroll', height: '80vh' }}>
          {blogs?.items?.length ? blogs?.items.map((item) => (
            <Link key={item.id} to={`/blogs/financial-consulting/${item.id}`} onClick={() => setUpdatedId(item.id)}>
              <BasicSection key={item.id} title={''} headTitle={lang === 'ar' ? item.name_ar : item.name_ar}
                body={''} image={endPoint + item.image} reverse={false} line={false}
                dataAosFadeRight="" dataAosFadeLeft=""
              />
            </Link>
          )) : ''}
        </Col>

      </Row>

    </section>
  );
}
